































































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { Countries } from '../utils/data/Countries.js';

@Component
export default class Home extends Vue {
  name = ''
  nameRules = [
    (v: string) => !!v || 'Full Name is required',
    (v: string) => (!!v && v.length > 1) || 'At least 2 characters',
    (v: string) => (!!v && v.length < 33) || 'No more than 32 characters',
    (v: string) => /[a-zA-Z0-9._]/.test(v) || 'Full Name invalid. Ex. Sample Full Name',
  ]
  email = ''
  emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /[a-zA-Z0-9._]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,3}/.test(v) || 'Email invalid. Ex. sample@email.com',
  ]
  phonePrefix = ''
  phone = ''
  phoneRules = [
    (v: string) => !!v || 'Phone Number is required',
    (v: string) => (!!v && v.length > 3) || 'At least 4 digits',
    (v: string) => (!!v && v.length < 15) || 'No more than 14 digits',
    (v: string) => /\+?[0-9]/.test(v) || 'Phone Number invalid. Ex. 123 456 7890',
  ]
  message = ''
  messageRules = [
    (v: string) => !!v || 'Message is required',
    (v: string) => (!!v && v.length > 1) || 'At least 2 characters',
    (v: string) => (!!v && v.length < 513) || 'No more than 512 characters',
    (v: string) => /[a-zA-Z0-9._-]{2,256}/.test(v) || 'Message invalid. Ex. Hi, I just want to say hello!',
  ]
  services = []
  servicesRules = [
    (v: string) => !!v || 'Services is required',
    (v: string) => v.length > 0 || 'At least one item should be selected',
  ]
  valid = true
  loading = false
  alertE = false
  alertS = false
  langCode = ''
  country = {}

  mounted() {
    this.getCountry();
  }

  getCountry(): void {
    axios.get('https://jsonip.com/?callback=?')
    .then(res => {
      const data = res.data.replace('?(', '').replace(');', '');
      const jsonData = JSON.parse(data);
      const url = `https://api.softguaro.com/countries/${jsonData.ip}`;
      axios.get(url)
      .then(res => {
        const myCountry = Countries.filter((item) => item.isoCode2 === res.data.countryCode);
        this.country = (myCountry.length > 0) ? { ...myCountry[0], ipApiData: res.data } : {};
        this.langCode = (myCountry.length > 0) ? myCountry[0].lang : 'en';
        this.phonePrefix = (myCountry.length > 0) ? `+${myCountry[0].phoneCode} ` : '';
      })
      .catch(error => console.error('Error: ', error));
    })
    .catch(error => console.error('Error: ', error));
  }

  @Emit()
  onKeydownPhone(event: KeyboardEvent) {
    const key = event.keyCode || event.charCode;
    if ((key >= 48 && key <= 57) || (key === 8) || (key === 9) || (key >= 96 && key <= 105)) {
      // this.handleCardNumberLength(event.target, key);
    } else {
      event.preventDefault();
    }
  }

  @Emit()
  validForm() {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  @Emit()
  createEmailContact() {
    if (this.validForm()) {
      this.loading = true;

      const url = 'https://api.softguaro.com/contacts';
      const data = {
        name: this.name,
        email: this.email,
        phone: `${this.phonePrefix}${this.phone}`,
        message: this.message,
        services: this.services,
        country: this.country,
        lang: this.langCode,
        type: 'full'
      };

      axios.post(url, data)
      .then(() => {
        (this.$refs.form as Vue & { reset: () => boolean }).reset();
        this.loading = false;
        this.alertS = true;
      })
      .catch(() => {
        this.loading = false;
        this.alertE = true;
      });
    }
  }
}
